import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Spinner from "@atlaskit/spinner";
import { detectPlatform } from "../utilities/detectPlatform";

const DeepLinking = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); 

  useEffect(() => {
    const checkPlatformAndRedirect = () => {
      const url = new URL(window.location.href);
      const referralCode = url.searchParams.get("referral");
      console.log("--> useEffect referralCode", referralCode);

      const userAgent = navigator.userAgent || "";
      console.log("--> useEffect userAgent", userAgent);

      const platform = detectPlatform(userAgent);
      console.log("--> useEffect platform", platform);

      if (referralCode) {
        if (platform === "android") {
          window.location.href =
            "https://play.google.com/store/apps/details?id=com.rasoda";
        } else if (platform === "ios") {
          window.location.href =
            "https://apps.apple.com/in/app/rasodaa/id1607453111";
        } else {
          setTimeout(() => {
            setLoading(false);
          }, 2000);
        } 
      }
    };

    checkPlatformAndRedirect();
  }, []);

  return (
    <div className="w-screen h-screen absolute bg-white z-50 inset-0 flex justify-center items-center">
      {loading ? (
        <Spinner interactionName="load" label="Loading" size="large" />
      ) : (
        <div>Please open this link using a mobile device</div>
      )}
    </div>
  );
};

export default DeepLinking;
